import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import styles from '../sass/components/answer.module.scss';
import getString from '../utils/get-string';

const AddressAccepted = ({strings}) => (
  <Layout>
    <Seo
      title={getString(strings, "11255643")}
    />

    <div className={styles.answer}>
      <Page type="success">
        <Header closeUrl="/lessons" />
        <Content>
          <h1 className={`h2 less-growing text-transform-uppercase`}>
            {getString(strings, "11255643")}
          </h1>
          <div className="page-text-container">
            <div className={`h6 ${styles.answer__text}`}>
              {getString(strings, "11255654")}
            </div>
          </div>
        </Content>
        <Footer>
          <FooterLinkNext
            to="https://www.pilsnerurquell.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {getString(strings, "11255660")}
          </FooterLinkNext>
        </Footer>
      </Page>
    </div>
  </Layout>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11255643", "11255654", "11255660"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <AddressAccepted {...data} {...props} />}
  />
)
